import { Component, ElementRef, Inject, Input, OnInit, Optional } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Item, Quote } from '@shared/models/quote.model';
import { QuoteService } from '@features/quote/services/quote.service';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconService } from '@ess-front/shared';
import { SafeHtml } from '@angular/platform-browser';
import { QuoteDialogResult } from '@shared/models/quote-dialog-result.model';

@Component({
  selector: 'ess-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  animations: [
    trigger('slideInOutLeft', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(0%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(-100%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOutRight', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(-100%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(0%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
  ],
  standalone: false,
})
export class QuoteComponent implements OnInit {
  @Input() hash: string | null = null;
  @Input() isModal = true;

  backIcon: SafeHtml | null;
  showConfirmation = false;
  quote$: Observable<Quote> | null = null;
  confirmationConfig: { hash: string; id: number } | undefined;
  haveServicesSwapped = false;

  constructor(
    private quoteService: QuoteService,
    private iconService: IconService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { hash: string },
    private elementRef: ElementRef,
    private matDialogRef: MatDialogRef<QuoteComponent>,
  ) {
    this.backIcon = this.iconService.getSVG('back');
    if (data && data.hash) {
      this.hash = data.hash;
      this.matDialogRef.beforeClosed().subscribe((result: QuoteDialogResult | undefined) => {
        if (result?.haveServicesSwapped === undefined) {
          matDialogRef.close({ haveServicesSwapped: this.haveServicesSwapped });
        }
      });
    }
  }

  ngOnInit(): void {
    if (this.hash) {
      this.quote$ = this.quoteService.getQuote$(this.hash);
    }
  }

  openConfirmation(item: Item): void {
    this.confirmationConfig = { id: item.id, hash: this.hash ?? '' };
    this.showConfirmation = true;
    if (this.isModal) this.scrollModalTop();
  }

  closeConfirmation(): void {
    this.showConfirmation = false;
    if (this.isModal) this.scrollModalTop();
  }

  onServiceSelectionSwap(): void {
    if (this.hash) {
      this.quote$ = this.quoteService.getQuote$(this.hash);
      this.haveServicesSwapped = true;
    }
  }

  private scrollModalTop(): void {
    this.elementRef.nativeElement.parentElement.scrollTo(0, 0);
  }
}
